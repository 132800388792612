/* @tailwind utilities; */

.im-w-full {
  width: 100% !important;
}

.user-avatar{
  -webkit-clip-path: url(#icons_squircle-96px);
  clip-path: url(#icons_squircle-96px);
  border-radius: 28px;
}

img { object-fit: cover; }

/* Zid fonts */
@font-face {
  font-family: "tsaolMedium";
  src: url("../fonts/FFShamelFamily-UniqueMedium.woff2") format("woff2"),
    url("../fonts/FFShamelFamily-UniqueMedium.woff") format("woff");
}

@font-face {
  font-family: "tsaolBold";
  src: url("../fonts/FFShamelFamily-UniqueBold.woff2") format("woff2"),
    url("../fonts/FFShamelFamily-UniqueBold.woff") format("woff");
}

@font-face {
  font-family: "tsaolRegular";
  src: url("../fonts/FFShamelFamily-UniqueBook.woff2") format("woff2"),
    url("../fonts/FFShamelFamily-UniqueBook.woff") format("woff");
}

@font-face {
  font-family: "tsaolLight";
  src: url("../fonts/FFShamelFamily-UniqueLight.woff2") format("woff2"),
    url("../fonts/FFShamelFamily-UniqueLight.woff") format("woff");
}

/* ------------------------------------- */
html, body{
  position: relative;
}

body {
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif;
  /* background: #f3f5f7; */
  background: #f9fafb;
  /* background: #fff; */
  overflow-x: hidden;
  height: 100%;
  scroll-behavior: smooth;
  font-size: 14px;
}

* {
  outline: none;
}
.Toastify__toast--default{
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif !important;
}

/* toast msg styles */
.errorToast{ border-top: 4px solid rgba(248, 113, 113); }
.successToast{ border-top: 4px solid rgba(52, 211, 153); }
.warnToast{ border-top: 4px solid rgba(251, 191, 36); }
.infoToast{ border-top: 4px solid rgba(96, 165, 250); }
/* toast msg styles */

.question {
  font-family: "tsaolBold", "Segoe UI", Tahoma, Verdana, sans-serif;
}

.rtl-dir {
  direction: rtl;
}
.ltr-dir {
  direction: ltr;
}

.transition {
  -webkit-transition: 250ms;
  transition: 250ms;
}

/* set quill editor to rlt */
.ql-editor {
  direction: rtl !important;
  text-align: right !important;
  min-height: 200px !important;
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.65rem !important;
}
.ql-editor strong,
.ql-editor b {
  font-family: "tsaolBold", "Segoe UI", Tahoma, Verdana, sans-serif !important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0.2em !important;
  margin-right: -1.3em !important;
  text-align: right !important;
}
.ql-snow .ql-editor blockquote {
  border-left: none !important;
  padding-left: 0 !important;
  border-right: 4px solid #ccc !important;
  padding-right: 10px !important;
  font-style: italic;
  color: #767575;
}
.ql-editor ol,
.ql-editor ul {
  padding-right: 1.5em !important;
}

.ql-tooltip {
  font-family: "tsaolRegular", "Segoe UI", Tahoma, Verdana, sans-serif !important;
}
.ql-snow .ql-tooltip a.ql-action::after {
  content: "تعديل" !important;
  padding-left: 0 !important;
  padding-right: 8px !important;
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: "إزالة" !important;
}
.ql-snow .ql-tooltip::before {
  content: "زيارة الرابط" !important;
  margin-left: 8px !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "حفظ" !important;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "أدخل الرابط" !important;
}

/* the read more button */

.readmore {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 8px 0;

  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, white);
}

.truncate-html{
  overflow: hidden;
  line-height: 1.75rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.bs-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .bs-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .bs-container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bs-container {
    max-width: 960px;
  }
}

@media (min-width: 985px) {
  .bs-container {
    max-width: 985px !important;
  }
}

@media (min-width: 1200px) {
  .bs-container {
    max-width: 1140px;
  }
}

@media (max-width: 991.98px) {
  .bs-container {
    max-width: 100%;
  }
}

.text-smaller {
  font-size: 13px;
}
.top-full {
  top: 100%;
}

/* ---- 404 page ---- */
._404_text {
  text-align: center;
  font-size: 10em;
  font-weight: bold;
}

/* ######### Skeleton loading animation ######## */
.skeleton-shimmer {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #e9e9e9;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#e9e9e9),
    color-stop(20%, #d8d8d8),
    color-stop(40%, #e9e9e9),
    to(#e9e9e9)
  );
  background-image: linear-gradient(
    to right,
    #e9e9e9 0%,
    #d8d8d8 20%,
    #e9e9e9 40%,
    #e9e9e9 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 130px;
  /* height: 104px; */
  position: relative;
}

.skeleton-shimmer div {
  background: #313236;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}


/* ###################################### */
/* ------------- Client styles ---------- */
/* ###################################### */

/* #### Animations #### */

.modal {
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
  z-index: 99;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}


/* ##### Nav bar ###### */
#secondNav,
#mainNav {
  -webkit-transition: 250ms;
  transition: 250ms;
  z-index: 9500;
}

#secondNav {
  top: 67px;
}

@media (max-width: 768px) {
  #nav-search-form {
    position: absolute;
    top: -100%;
    right: 0%;
    width: 100%;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  }
}
.opened-nav-search-form {
  top: 100% !important;
}

#profileIconDropdown::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 20px;
  width: 10px;
  height: 10px;
  background: #63b3ed;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: -1;
}

/* #### Home page #### */

.home-topic-img{ max-width: 95px; }

@media(max-width: 750px){
  .home-topic-img{ max-width: 70px; }
}

/* for html text */
.quillHtmlText { word-break: break-word !important; }

.quillHtmlText ol { list-style-type: decimal; }

.quillHtmlText ul {
  list-style-type: disc;
}

.quillHtmlText ol,
.quillHtmlText ul {
  padding-right: 0.3rem !important;
}

.quillHtmlText ul li {
  position: relative;
  margin-right: 1.5rem;
  padding-bottom: 0.7rem;
}

.quillHtmlText a{ color: #8b5da5; }

.quillHtmlText blockquote{
  border-right: 4px solid #d5d5d5;
  color: #999;
}
.quillHtmlText blockquote em{ padding-right: 0.5rem; }
.quillHtmlText .ql-syntax{
  padding: 10px;
  display: block;
  background: #e3e3e3;
  border-radius: 5px;
}

/* ### Question card ### */

.answer-textarea,
.answer-card {
  word-break: break-word !important;
}

.answer-textarea ol,
.answer-card ol {
  list-style-type: decimal;
}

.answer-textarea ul,
.answer-card ul {
  list-style-type: disc;
}

.answer-textarea ol,
.answer-textarea ul,
.answer-card ol,
.answer-card ul {
  padding-right: 0.3rem !important;
}

.answer-textarea a,
.answer-card a {
  color: #8b5da5;
}

.answer-card ul li {
  position: relative;
  margin-right: 1.5rem;
  padding-bottom: 0.7rem;
}

.addResponseBtn {
  color: #8b5da5;
  background: rgba(145, 93, 165, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.addResponseBtn:hover {
  background:rgba(145, 93, 165, 0.2);
}

.flafiBtn {
  color: #8b5da5;
  background: rgba(145, 93, 165, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.flafiBtn:hover {
  background: rgba(80, 146, 225, 0.2);
}

/* ##### Topics card ##### */
.mobileTopic {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.search-sort,
.desktopTopicsCard {
  top: 70px;
}

/* .desktopTopicsCard{
    max-height: 670px;
    overflow-y: scroll;
} */

/* desktop topics card scroll bar */
.wrapper,
.scroll-wrapper {
  padding: 5px;
}
.scroll-wrapper.mac + .scrollBarContainer {
  background-color: transparent;
}
.scrollBarContainer.animate .scroll {
  -webkit-transition: 2s opacity ease;
  transition: 2s opacity ease;
  -ms-transition: 2s opacity ease;
  opacity: 0;
}
.scrollBarContainer:hover .scroll {
  opacity: 0.5;
  -webkit-transition: none;
  transition: none;
  -ms-transition: none;
}
.unselectable {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* ######## Answer card ######### */
.answer-card {
  /* font-size: 15px !important; */
  line-height: 1.95;
}

.answer-card .answer-body {
  overflow: hidden;
}

.answer-body blockquote {
  border-right: 5px solid #d8d8d8;
  padding-right: 7px;
  font-style: italic;
  color: #767575;
}

.pulse-stop {
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 rgba(245, 101, 101, 0.555);
  box-shadow: 0 0 0 rgba(245, 101, 101, 0.555);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}
.pulse-stop:hover {
  -webkit-animation: none;
  animation: none;
}

.pulse-hover {
  border-radius: 50%;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 rgba(245, 101, 101, 0.555);
  box-shadow: 0 0 0 rgba(245, 101, 101, 0.555);
}
.pulse-hover:hover {
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 101, 101, 0.555);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(197, 48, 48, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(197, 48, 48, 0);
  }
}
@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 101, 101, 0.555);
    box-shadow: 0 0 0 0 rgba(245, 101, 101, 0.555);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(197, 48, 48, 0);
    box-shadow: 0 0 0 10px rgba(197, 48, 48, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(197, 48, 48, 0);
    box-shadow: 0 0 0 0 rgba(197, 48, 48, 0);
  }
}

/* ####### Single answer page ###### */
.answers-line {
  height: 1px;
}

/* ####### User profile ###### */
.editAnswerEditor {
  min-height: 15rem !important;
  line-height: 37px;
}
.user-img-edit::before {
  content: "";
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}

.edit-img-icon {
  position: absolute;
  top: 30px;
  left: 35px;
  color: #ccc;
  z-index: 5;
}

.user-edit-info-pentbn {
  top: 10px;
  left: 10px;
}

.verified-email-img {
  max-width: 200px;
}


/* ####### Search page ###### */
.mobile-seach-sort > li {
  margin-bottom: 0 !important;
}

/* ####### Static pages ###### */
.static-page-banner {
  background: #56ccf2;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2f80ed),
    to(#56ccf2)
  );
  background: linear-gradient(to right, #2f80ed, #56ccf2);
  height: 400px;
}
.about-page-bg {
  background: #396afc; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2948ff),
    to(#396afc)
  );
  background: linear-gradient(
    to right,
    #2948ff,
    #396afc
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 400px;
}

/* ###### Modal ###### */
/* .modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9800;
  visibility: hidden;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms;
} */

/* .modal {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  transition: 0.1s ease-in-out;
}

.modal-wrapper .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.modal-is-open {
  visibility: visible;
  opacity: 1;
}
.modal-is-open > .modal {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.modal-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

@media (min-width: 576px) {
  .modal {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal.modal-lg {
    max-width: 800px !important;
  }
  .modal-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
} */
